import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton} from '@mui/material'
import {Images} from '../../../../_metronic/partials/widgets/React_Table/Images'
import ReactTableWithPagention from '../../../../_metronic/partials/widgets/React_Table/ReactTableWithPagention'
import useAuthContext from '../../../Auth/AuthContext'

const PendingOrdersHome = () => {
  const {pending_order, playSound, setPenidng_orders, check_role} = useAuthContext()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [inputValue, setInputValue] = useState(null)

  const navigate = useNavigate()

  const [check, setCheck] = useState({
    show: false,
  })

  const getData = async () => {
    const {data} = await axiosInstance.get(`/admin-pending-orders?page=${currentPage}`, {
      params: {name: inputValue},
    })
    setItems(data.data)
    setTotalPages(data.meta.last_page)
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1)
  }
  useEffect(() => {
    getData()
  }, [inputValue, currentPage, pending_order])

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: ({row}) => <div className='w-100 flex-center'># {row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.User'}),
      accessor: (row) => `${row.user?.name} ${row.user?.id}`,
      Cell: (row) =>
        row.row.original.user && (
          <Link to={`/Users/Edit-Users/}${row.row.original.user.id}`}>
       
          <div className='d-flex align-items-center ps-3'>
            <Images img={row.row.original.user.img} name={row.row.original.user.name} />
            <div className='flex-grow-1'>
              <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                {row.row.original.user.name}
              </div>
              <span className='text-muted d-block fw-semibold text-start'>
                #{row.row.original.user.id}
              </span>
            </div>
          </div>
          </Link>
        ),
      // Define a custom filter function
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const user = row.values[id] // id will be 'user' which is the accessor
          return user.toLowerCase().includes(filterValue.toLowerCase()) // Search on concatenated string
        })
      },
    },
    {
      Header: intl.formatMessage({id: 'Table.Total'}),
      accessor: 'total',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.total}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Status'}),
      accessor: 'status',
      Cell: ({row}) => (
        <div className='w-100 flex-center'>
          {row.original.status === 'failed' && (
            <span className='badge badge-light-danger'>{row.original.status}</span>
          )}

          {row.original.status === 'pending' && (
            <span className='badge badge-light-warning'>{row.original.status}</span>
          )}

          {row.original.status === 'completed' && (
            <span className='badge badge-light-success'>{row.original.status}</span>
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.AdminApproved'}),
      accessor: 'admin_approved',
      Cell: ({row}) => (
        <div className='w-100 flex-center'>
          {row.original.admin_approved === 'refused' && (
            <span className='badge badge-light-danger'>{row.original.admin_approved}</span>
          )}

          {row.original.admin_approved === 'pending' && (
            <span className='badge badge-light-warning'>{row.original.admin_approved}</span>
          )}

          {row.original.admin_approved === 'approved' && (
            <span className='badge badge-light-success'>{row.original.admin_approved}</span>
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.payment_type'}),
      accessor: 'payment_type',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.payment_type}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Date'}),
      accessor: 'created_at',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.created_at}</div>,
    },

    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div className='d-flex flex-center'>
          {check.show && (
            <Link
              to={`/PendingOrders-details/${row.original.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.View'})}
            </Link>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      show: check_role('show-pending-orders'),
    })

    if (!check_role('browse-pending-orders')) {
      navigate('/')
    }
    getData()
    setPenidng_orders(0)
  }, [])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.PendingOrders'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Orders'})}
            </li>
          </ul>
        </div>
      </div>

      {!loading ? (
        <ReactTableWithPagention
          columns={columns}
          data={items}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          inputValue={inputValue}
          setInputValue={setInputValue}
          customComponent={<></>}
        />
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </motion.nav>
  )
}

export default PendingOrdersHome
